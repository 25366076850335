import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ALL_VALUE, MAX_MAX_RESULT_COUNT } from "appConfiguration";
import { usePublicShops } from "./usePublicShops";

/**
 * Custom hook that fetches public shop options and returns the options, loading state, error state, and fetched data.
 *
 *
 * @param filter - Optional filter object to apply when fetching public shops.
 * @default filter.MaxResultCount = 1000
 * @param shouldFetch - Boolean indicating whether to fetch the data immediately or not. Defaults to true.
 * @param swrOptions - Optional options object to pass to the underlying SWR hook.
 * @param includeAllOption - Boolean indicating whether to include an "All" option in the options list. Defaults to false.
 * @returns An object containing the options, loading state, error state, and fetched data.
 */
export function usePublicShopOptions(
  filter?: Parameters<typeof usePublicShops>[0],
  shouldFetch: boolean = true,
  swrOptions?: Parameters<typeof usePublicShops>[2],
  includeAllOption = false
) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useSharedEntitiesShopsOptions",
  });
  const { data, isLoading, isError } = usePublicShops(
    {
      MaxResultCount: MAX_MAX_RESULT_COUNT,
      ...filter,
    },
    shouldFetch,
    swrOptions
  );
  const options = useMemo(() => {
    const potentialOptions =
      data?.items
        ?.filter(
          (shopInfo): shopInfo is { name: string; id: string } =>
            typeof shopInfo.name === "string" && typeof shopInfo.id === "string"
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((shopInfo) => {
          const label = shopInfo.name;
          const value = shopInfo.id;
          return { label, value };
        }) ?? [];
    if (includeAllOption) {
      potentialOptions.unshift({ label: t("allShops"), value: ALL_VALUE });
    }
    return potentialOptions;
  }, [data?.items, includeAllOption, t]);
  return { options, isLoading, isError, data };
}
