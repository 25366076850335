import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { EMPTY_VALUE, SUPPORT_EMAIL } from "appConfiguration";
import { PageHeader } from "core/components/PageHeader";
import { getBrand } from "core/utils/getBrand";
import { usePermissionContext } from "core/hooks/usePermissionContext";
import { useRegion } from "core/hooks/useRegion";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { usePreferredRegion } from "core/hooks/usePreferredRegion";
import { RegionCode } from "brandRegionConfig";
import { useRegionNameFormatter } from "core/hooks/useRegionNameFormatter";
import { useClearSWRCache } from "core/hooks/useClearSWRCache";
import { extractRegionsForBrand } from "./extractRegionsForBrand";

export function UnauthorizedContextPage() {
  const currentRegion = useRegion();
  const { brandCode } = getBrand();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:UnauthorizedContextPage",
  });
  const regionNameFormatter = useRegionNameFormatter();
  const subTitle = currentRegion
    ? t("region", { region: regionNameFormatter(currentRegion) ?? EMPTY_VALUE })
    : undefined;
  const { value: preferredRegion, setValue: setPreferredRegion } =
    usePreferredRegion();

  const { pathname, query, replace } = useRouter();
  const { data } = usePermissionContext();
  const brandRegions = extractRegionsForBrand(data ?? [], brandCode);
  const regionOptions: {
    label: string;
    regionCode: RegionCode;
  }[] = brandRegions.map((region) => ({
    label: regionNameFormatter(region) ?? EMPTY_VALUE,
    regionCode: region as RegionCode,
  }));

  // If the preferred region (which defaults to "ca" for new users) is not available for this user,
  // the region selected will become the preferred region.
  const invalidPreferredRegion = !brandRegions.includes(preferredRegion);

  const clearCache = useClearSWRCache();
  const handleRegionChange = async (region: RegionCode) => {
    await replace({ pathname, query: { ...query, region } });
    if (invalidPreferredRegion) {
      setPreferredRegion(region);
    }
    clearCache();
  };

  return (
    <Box component="main">
      <PageHeader
        title={t("pageTitle")}
        titleTypographyProps={{ color: "error.main" }}
        subTitle={subTitle}
        subTitleTypographyProps={{ color: "error.main", variant: "h6" }}
      />

      {regionOptions.length > 0 && (
        <>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("availableRegionsMessage")}
          </Typography>
          <Stack spacing={2} my={2}>
            {regionOptions.map(({ label, regionCode }) => (
              <Button
                key={label}
                onClick={async () => {
                  await handleRegionChange(regionCode);
                }}
                sx={{ width: "100%" }}
                variant="outlined"
              >
                {label}
              </Button>
            ))}
          </Stack>
          {invalidPreferredRegion && (
            <Typography color="text.secondary">
              {t("invalidPreferredRegionMessage")}
            </Typography>
          )}
        </>
      )}
      <Typography my={4}>
        <Trans i18nKey="Next:Core:UnauthorizedContextPage.message">
          Something isn&apos;t right? Please contact your supervisor or
          <MuiLink
            color="primary.main"
            fontWeight="bold"
            underline="none"
            href={`mailto: ${SUPPORT_EMAIL}`}
          >
            {SUPPORT_EMAIL}
          </MuiLink>
        </Trans>
      </Typography>
    </Box>
  );
}
