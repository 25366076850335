import { useMemo } from "react";
import type { PermissionContext } from "core/entities";
import { getBrandCode } from "core/utils/getBrandCode";
import { extractValueFromContext } from "core/utils/extractValueFromContext";
import {
  BRAND_PERMISSION_CONTEXT_KEY,
  REGION_PERMISSION_CONTEXT_KEY,
} from "core/entities/permissionContextDefs";
import { usePermissionContext } from "./usePermissionContext";
import { useBrandRegionCode } from "./useBrandRegionCode";

/**
 * This hook returns the user's permission contexts that match the current brand and region.
 */
export function useUserPermissionContextsInCurrentBrandRegion() {
  const brandCode = getBrandCode();
  const brandRegionCode = useBrandRegionCode();

  const {
    data: userPermissionContexts,
    isLoading,
    isError,
  } = usePermissionContext();

  const userContextsInCurrentBrandRegion = useMemo(() => {
    if (
      !Array.isArray(userPermissionContexts) ||
      !brandCode ||
      !brandRegionCode ||
      isLoading ||
      isError
    ) {
      return undefined;
    }

    return userPermissionContexts.filter(
      (userPermContext): userPermContext is PermissionContext => {
        const { context: contextList, permissions } = userPermContext;
        if (!Array.isArray(contextList)) {
          return false;
        }
        const brandValue = extractValueFromContext(
          contextList,
          BRAND_PERMISSION_CONTEXT_KEY
        );
        const isCorrectBrand =
          brandValue?.toLowerCase() === brandCode.toLowerCase();

        const regionValue = extractValueFromContext(
          contextList,
          REGION_PERMISSION_CONTEXT_KEY
        );
        const isCorrectRegion =
          regionValue?.toLowerCase() === brandRegionCode.toLowerCase();
        return isCorrectBrand && isCorrectRegion && Array.isArray(permissions);
      }
    );
  }, [brandCode, brandRegionCode, isError, isLoading, userPermissionContexts]);
  return { contexts: userContextsInCurrentBrandRegion, isLoading, isError };
}
