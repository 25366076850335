import FetchBackend from "i18next-fetch-backend";
import i18n, { InitOptions } from "i18next";
import { API_URL, isDevelopment, isBrowser } from "appConfiguration";
import { initReactI18next } from "react-i18next";
import { copyTextToClipboard } from "core/utils/copyTextToClipboard";

const shouldHandleMissingKeys = isBrowser && isDevelopment;

const missingKeysMap = new Map<string, Set<string>>();

export function copyTranslationsToClipboard() {
  if (missingKeysMap.size === 0) {
    return;
  }
  const output = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [namespace, keys] of missingKeysMap) {
    const comment = `// ${namespace}`;
    const keysString = [...keys]
      .sort()
      .map((key) => {
        const value = key.split(".").at(-1) || key;
        return `"${key}":"${value}"`;
      })
      .join(",\n");
    const formattedString = `${comment}\n${keysString},`;
    output.push(formattedString);
  }
  copyTextToClipboard(output.join("\n"));
}

export const namespaces = [
  "Fixhub",
  "PaymentRequest",
  "NewsEvents",
  "DocumentManagementSystem",
] as const;

const config: InitOptions = {
  backend: {
    loadPath: (lng: string, ns: string) => {
      const url = new URL("/api/app/localization", API_URL);
      url.searchParams.set("locale", lng);
      url.searchParams.set("resource", ns);
      return url.href;
    },
  },
  fallbackLng: isDevelopment ? false : "en",
  fallbackNS: ["Fixhub"],
  debug: false,
  ns: "Fixhub",
  defaultNS: "Fixhub",
  lng: "en",
  nsSeparator: ";",
  saveMissing: shouldHandleMissingKeys,
  missingKeyHandler: (lng, ns, key) => {
    if (!lng.includes("en")) {
      return;
    }
    const missingKeysSet = missingKeysMap.get(ns) || new Set<string>();
    if (missingKeysSet.has(key)) {
      return;
    }
    missingKeysSet.add(key);
    missingKeysMap.set(ns, missingKeysSet);
    // eslint-disable-next-line no-console
    console.log(
      `%cMissing Key "${key}"`,
      "color:red;font-weight:bold;",
      missingKeysMap
    );
  },
  interpolation: {
    escapeValue: false,
    format(value, format, lng, edit) {
      const formatParams = edit?.formatParams;
      if (format === "number") {
        return new Intl.NumberFormat(lng, formatParams).format(value);
      }
      if (format === "datetime") {
        return new Intl.DateTimeFormat(lng, formatParams).format(value);
      }
      if (format === "currency") {
        return new Intl.NumberFormat(lng, {
          style: "currency",
          currency: "CAD",
          currencyDisplay: "narrowSymbol",
        }).format(value);
      }
      return value;
    },
  },
};

i18n.use(FetchBackend).use(initReactI18next).init(config);

export default i18n;
