import { DocDocumentDto } from "features/DMS/entities/DmsDefs";
import { ALL_VALUE } from "appConfiguration";
import { useTranslation } from "react-i18next";
import { useViewableDMSPermissionOptions } from "../PermissionManagement";
import { dmsDocumentPermissionsToViewablePermissionsFormState } from "../PermissionManagement/ViewablePermissionsManagement/utils";

export interface ReadablePermissions {
  brands: string[];
  regions: string[];
  departments: string[];
  corporateRoles: string[];
  shopRoles: string[];
  shopNames: string[];
}

export function useReadablePermissions(
  permissions: DocDocumentDto["permissions"],
  docLibraryId: string
): ReadablePermissions | undefined {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useSharedEntitiesShopsOptions",
  });

  const {
    brands: brandCodes,
    corporateRoles: corporateRoleIds,
    departments: departmentIds,
    regions: regionCodes,
    shopRoles: shopRoleIds,
  } = dmsDocumentPermissionsToViewablePermissionsFormState(permissions);

  const shopNames =
    permissions?.reduce((acc, perm) => {
      if (perm.shopId === ALL_VALUE) {
        acc.push(t("allShops"));
        return acc;
      }
      if (perm.shopName && !acc.includes(perm.shopName)) {
        acc.push(perm.shopName);
      }
      return acc;
    }, [] as string[]) ?? [];

  const {
    brandOptions,
    isLoading,
    corporateRoleOptions,
    departmentOptions,
    regionOptions,
    shopRoleOptions,
  } = useViewableDMSPermissionOptions(docLibraryId);
  if (isLoading) {
    return undefined;
  }

  function mapLabels(
    values: string[],
    options: { label: string; value: string }[]
  ) {
    return values
      .map((val) => options.find((op) => op.value === val)?.label)
      .filter((label): label is string => typeof label === "string");
  }
  const brands = mapLabels(brandCodes, brandOptions);
  const regions = mapLabels(regionCodes, regionOptions);
  const departments = mapLabels(departmentIds, departmentOptions);
  const corporateRoles = mapLabels(corporateRoleIds, corporateRoleOptions);
  const shopRoles = mapLabels(shopRoleIds, shopRoleOptions);
  return {
    brands,
    regions,
    departments,
    corporateRoles,
    shopRoles,
    shopNames,
  };
}
