import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { useRouter } from "next/router";
import { enCA, frCA, es, de } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { ReactNode, useContext } from "react";
import { LocaleOverrideContext } from "core/hooks/useIntlFormatterLocales";
import { SupportedLocales } from "appConfiguration";

const localeMap: Record<SupportedLocales, Locale> = {
  en: enCA,
  fr: frCA,
  es,
  "de-DE": de,
};

export function DatePickerLocalizationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const overrideLocale = useContext(LocaleOverrideContext);
  const { locale: routerLocale } = useRouter();
  const locale = overrideLocale || routerLocale;
  const providerLocale = localeMap[locale as keyof typeof localeMap] || enCA;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={providerLocale}
    >
      {children}
    </LocalizationProvider>
  );
}
