import { brandCodes, MAX_MAX_RESULT_COUNT } from "appConfiguration";
import { useDepartmentOptions } from "core/hooks/useDepartmentOptions";
import { useRoleOptions } from "core/hooks/useRoleOptions";
import { usePublicShopOptions } from "core/hooks/usePublicShopOptions";
import { useDMSBrandRegionOptions } from "../useDMSBrandRegionOptions";

export function useViewableDMSPermissionOptions(
  docLibraryId: string,
  selectedBrandShortCodes: string[] = [],
  selectedCountryIso2AlphaCodes: string[] = []
) {
  const {
    brandOptions,
    regionOptions,
    brandRegionMap,
    isLoading: isBrandRegionOptionsLoading,
  } = useDMSBrandRegionOptions("document", docLibraryId);

  const {
    departmentBrandMap,
    departmentOptions,
    isLoading: isDepartmentsLoading,
  } = useDepartmentOptions([...brandCodes]);

  const {
    isLoading: isCorporateRoleOptionsLoading,
    roleOptions: corporateRoleOptions,
  } = useRoleOptions("Corporate");

  const { roleOptions: shopRoleOptions, isLoading: isShopRoleOptionsLoading } =
    useRoleOptions("Shop");

  const { options: shopOptions, isLoading: isShopOptionsLoading } =
    usePublicShopOptions(
      {
        BrandShortCodes: selectedBrandShortCodes,
        CountryIso2AlphaCodes: selectedCountryIso2AlphaCodes,
        ShopStatus: "Active",
        MaxResultCount: MAX_MAX_RESULT_COUNT,
      },
      true,
      undefined,
      true
    );

  const isLoading =
    isBrandRegionOptionsLoading ||
    isDepartmentsLoading ||
    isCorporateRoleOptionsLoading ||
    isShopOptionsLoading ||
    isShopRoleOptionsLoading;

  return {
    isLoading,
    brandOptions,
    regionOptions,
    brandRegionMap,
    departmentOptions,
    corporateRoleOptions,
    shopOptions,
    shopRoleOptions,
    departmentBrandMap,
  };
}
